<template>
  <modal
    @forced-nextlink="goNextLink()" :huge="popupSet.huge"
  >
    <template v-slot:body>
      <div>
        <div class="default-pop frame" v-if="popType === 'warn'">
          <div class="close" @click="close()">
            <img src="@assets/images/util/x.svg" />
          </div>
          <div class="pop-img" v-if="!imgHide">
            <img :src="require(`@/assets/images/popup/${popImg}`)" />
          </div>
          <div class="content">
            <p v-html="contentMutated"></p>
          </div>
          <div class="btn-wrap" :class="{ alone: confirmBtnText === null || cancelBtnText === null }">
            <button
              v-text="cancelBtnText"
              type="button"
              @click="close()"
              ref="close"
              v-if="cancelBtnText !== null"
            />
            <button
              class="active"
              v-text="confirmBtnText"
              type="button"
              ref="action"
              @click="action()"
              v-if="confirmBtnText !== null"
            />
          </div>
        </div>
        <div class="default-pop frame imgPopWrap" v-if="popType === 'img'">
          <div class="close" @click="close()">
            <img src="@assets/images/util/x.svg" />
          </div>
          <div class="imgWrap">
            <p><img :src='`${popupSet.imgPopUp}`'></p>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
import vSelect from 'vue-select';
import Modal from './Modal.vue';

export default {
  props: {
    popupSet: {
      confirmBtnText: {
        type: String,
      },
      cancelBtnText: {
        type: String,
      },
      popType: {
        type: String,
        default: 'warn',
      },
      popImg: {
        type: String,
        default: 'warning.svg',
      },
      popImgArr: {
        type: Array,
      },
      content: {
        type: String,
      },
      imgHide: {
        type: Boolean,
        default: false,
      },
      nextLink: {
        type: String,
        default: '/',
      },
      imgPopUp: {
        type: String,
        default: '',
      },
      huge: {
        type: Boolean,
        default: false,
      },
      enterTrigger: {
        type: Boolean,
        default: false,
      },
    },
  },
  components: {
    Modal,
    vSelect,
  },
  data() {
    return {
      popType: this.popupSet.popType || 'warn',
      popImgArr: this.popupSet.popImgArr,
      popImg: this.popupSet.popImg || 'warning.svg',
      cancelBtnText: this.popupSet.cancelBtnText,
      confirmBtnText: this.popupSet.confirmBtnText,
      content: this.popupSet.content,
      imgHide: this.popupSet.imgHide,
      nextLink: this.popupSet.nextLink,
      getEnterTrigger: this.popupSet.enterTrigger,
    };
  },
  mounted() {
    this.enterFocus();
    this.checkEnter();
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
    contentMutated() {
      if (this.content !== undefined && this.content.indexOf('\n') !== 1) {
        return this.content.split('\n').join('<br />');
      }
      return this.content;
    },
  },
  methods: {
    ...mapMutations('basic', ['SET_POPUP']),
    // 로그인 페이지에서 비밀번호 입력 후 엔터로 메서드 진행시킬때 엔터로 팝업창 종료!!
    enterFocus() {
      const enterT = this.getEnterTrigger;
      if (enterT === 'action') {
        this.$refs.action.focus();
      } else if (enterT === 'close') {
        this.$refs.close.focus();
      }
    },
    // 로그인 페이지에서 비밀번호 입력 후 클릭으로 메서드 진행시킬때 엔터로 팝업창 종료!!(참고: enterFocus메서드는 버튼에 focus하여 action나 close메서드를 진행 시키려고 쓰는 메서드이나 클릭하면 포커스가 다른곳으로 이동하여 메서드를 진행시킬 수 없음)
    checkEnter() {
      window.addEventListener('keyup', (e) => {
        if (e.keyCode === 13) {
          if (this.popupSet.enterTrigger === 'action') {
            this.action();
            this.$emit('enterFlag', true);
          }
          if (this.popupSet.enterTrigger === 'close') {
            this.close();
            this.$emit('enterFlag', true);
          }
          return false;
        }
      });
    },
    action() {
      this.SET_POPUP(false);
      if (this.isApiCall) {
        this.$emit('api-called');
      } else if (this.nextLink !== undefined) {
        this.$router.push(this.nextLink);
      }
    },
    close() {
      if (this.destination && this.nextLink !== undefined) {
        this.$router.push(this.nextLink);
      } else if (this.returnList !== undefined && this.returnList) {
        this.$router.go(-1);
      }
      this.SET_POPUP(false);
    },
    goNextLink() {
      this.$router.push(this.nextLink);
    },
  },
  created() {
    console.log('가져온 팝업아이템', this.popupSet);
  },
};
</script>

<style lang="scss" scoped>
.medium {
  .modal-wrapper {
    .modal-container {

      .content .img-wrap {
        max-width: 590px;
        margin: 0 auto 60px;
        img {
          width: 100%;
        }
      }
    }
  }
}

.frame {
  padding: 30px;
  min-height: 300px;
}
img {
  max-height: 600px;
  max-width: 1200px;
  height: auto;
  width: auto;
}
h3.title {
  @include initfontKor(
    $font-size: 26px,
    $line-height: 36px,
    $font-family: "AppleSDGothicNeoB00",
    $color: #292a2b,
    $letter-spacing: -0.52px,
    $text-align: center
  );
  margin: 0 0 12px 0;
}
.default-pop {
  width: inherit;
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1;
    &.hide {
      opacity: 0;
    }
  }
  .pop-img {
    max-width: 105px;
    margin: 0 auto 32px auto;
    display: flex;
    justify-content: center;
  }
  .content {
    p {
      @include initfontKor(
        $font-size: 24px,
        $line-height: 30px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #292a2b,
        $letter-spacing: -0.6px,
        $text-align: right
      );
      text-align: center;
      margin: 0 0 20px 0;
    }
  }
  .btn-wrap {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    &.alone {
      button {
        width: 100%;
        border-radius: 0 0 10px 10px;
        background: #fe6867;
        color: #fff;
      }
    }
    button {
      width: 50%;
      height: 60px;
      margin: 0;
      background: #eaebf1;
      border: 0;
      cursor: pointer;
      @include initfontKor(
        $font-size: 26px,
        $line-height: 36px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #292a2b,
        $letter-spacing: -0.52px,
        $text-align: center
      );
      &:first-of-type {
        margin: 0;
      }
      &.active {
        background: $pointcolor__basic;
        color: #fff;
      }
    }
  }
  &.imgPopWrap{
    .pop-img{
      width:100%;
    }
    .imgWrap{
      // margin-bottom:50px;
        p{
          text-align:center;
          width:50%; margin:0 auto;
          img{max-width:100%; max-height:440px;}
        }
      }
  }
}
</style>
