import { questionnaire } from '@/api';

const state = {
  questionnaire: {
    /**
    drId: localStorage.getItem('drId') || '',
     */
  },
};

const mutations = {
  /**
  HACK_TOKEN(state, token) {
    state.auth.token = token;
  }
  */
};

const getters = {
  /**
  GET_CHECK_RESULT: (state) => state.auth.isCheck
   */
};

const actions = {
  FETCH_COUNT() {
    return questionnaire.fetchCount().then((data) => (data.response ? data.response : data));
  },
  FETCH_LIST({}, data) {
    return questionnaire.fetchList(data);
  },
  FETCH_SEARCH_LIST({}, data) {
    return questionnaire.fetchSearchList(data);
  },
  FETCH_ANSWER({}, payload) {
    return questionnaire.fetchAnswer(payload);
  },
  FETCH_ANSWER_UPDATE({}, payload) {
    return questionnaire.fetchAnswerUpdate(payload);
  },
};
export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
