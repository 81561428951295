import axios from 'axios';
import { getCookie, deleteCookies } from '@/utils/cookie';

const domain = process.env.VUE_APP_API_URL;
const request = {
  get(path, header) {
    const currentUrl = document.location.href;
    console.log('get Api Current URL', currentUrl);
    console.log('get Path', path);
    return axios
      .get(`${domain + path}`, header)
      .catch((error) => {
        console.log('domain', domain);
        if (error.response) {
          console.log('error', error);
          console.log('error response', error.response);
          if (error.response.status === 500) {
            console.log('500 ERROR 진입:');
            console.log('path:', path);
          }
          if (error.response.statusText === 'Unauthorized') {
            console.log('AAAAAAAAAAAAAAAAAAAAAA', window.app);
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            deleteCookies();

            // localStorage.removeItem('token');
            // localStorage.removeItem('userId');
            // localStorage.removeItem('userName');
            // localStorage.removeItem('storeId');
            // const url = "/login";
            // document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log('err request', error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log('Error', error.message);
        }
      })
      .finally(() => {
        console.log('[localStorage] 1.get : ', localStorage);
      });
  },
  patch(path, data, header) {
    const currentUrl = document.location.href;
    console.log('patch Api Current URL', currentUrl);
    console.log('patch Path', path);
    return axios
      .patch(`${domain + path}`, data, header)
      .catch((error) => {
        if (error.response) {
          console.log('error', error);
          console.log('error response', error.response);
          if (error.response.status === 500) {
            console.log('500 ERROR 진입:');
            console.log('path:', path);
          }
          if (error.response.statusText === 'Unauthorized') {
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            deleteCookies();

            // localStorage.removeItem('token');
            // localStorage.removeItem('userId');
            // localStorage.removeItem('userName');
            // localStorage.removeItem('storeId');
            const url = '/login';
            document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log('err request', error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log('Error', error.message);
        }
      })
      .finally(() => {
        console.log('[localStorage] 2.patch : ', localStorage);
      });
  },
  post(path, data, header) {
    const currentUrl = document.location.href;
    console.log('post Api Current URL', currentUrl);
    console.log('post Path', path);
    return axios
      .post(`${domain + path}`, data, header)
      .catch((error) => {
        console.log('찐에러', error);
        if (error.response) {
          console.log('error', error);
          console.log('error response', error.response);
          if (error.response.status === 500) {
            console.log('500 ERROR 진입:');
            console.log('path:', path);
          }
          if (error.response.statusText === 'Unauthorized') {
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            deleteCookies();

            // localStorage.removeItem('token');
            // localStorage.removeItem('userId');
            // localStorage.removeItem('userName');
            // localStorage.removeItem('storeId');
            const url = '/login';
            document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log('err request', error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log('Error', error.message);
        }
      })
      .finally(() => {
        console.log('[localStorage] 3.post : ', localStorage);
      });
  },
  delete(path, header) {
    const currentUrl = document.location.href;
    console.log('delete Api Current URL', currentUrl);
    console.log('delete Path', path);
    return axios
      .delete(`${domain + path}`, header)
      .catch((error) => {
        if (error.response) {
          console.log('error', error);
          console.log('error response', error.response);
          if (error.response.status === 500) {
            console.log('500 ERROR 진입:');
            console.log('path:', path);
          }
          if (error.response.statusText === 'Unauthorized') {
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            deleteCookies();

            // localStorage.removeItem('token');
            // localStorage.removeItem('userId');
            // localStorage.removeItem('userName');
            // localStorage.removeItem('storeId');
            const url = '/login';
            document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log('err request', error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log('Error', error.message);
        }
      })
      .finally(() => {
        console.log('[localStorage] 4.delete : ', localStorage);
      });
  },
  put(path, data, header) {
    const currentUrl = document.location.href;
    console.log('put Api Current URL', currentUrl);
    console.log('put Path', path);
    return axios
      .put(`${domain + path}`, data, header)
      .catch((error) => {
        if (error.response) {
          console.log('error', error);
          console.log('error response', error.response);
          if (error.response.status === 500) {
            console.log('500 ERROR 진입:');
            console.log('path:', path);
          }
          if (error.response.statusText === 'Unauthorized') {
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            deleteCookies();

            // localStorage.removeItem('token');
            // localStorage.removeItem('userId');
            // localStorage.removeItem('userName');
            // localStorage.removeItem('storeId');
            const url = '/login';
            document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log('err request', error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우identitySuccess
          console.log('Error', error.message);
        }
      })
      .finally(() => {
        console.log('[localStorage] 5.put : ', localStorage);
      });
  },
};

export const auth = {};
export const util = {
  fetchLogin(apiParam) {
    return request
      .post('/user/login', apiParam, {
        headers: {},
      })
      .then(({ data }) => data);
  },
  Logout(apiParam) {
    return request
      .post('/user/logout', apiParam, {
        headers: {
          Authorization: `Bearer ${getCookie('token')}`,
        },
      })
      .then((data) => data);
  },
  fetchInfo() {
    return request
      .get('/user/user_info', {
        headers: {
          Authorization: `Bearer ${getCookie('token')}`,
        },
      })
      .then(({ data }) => data);
  },
};
export const questionnaire = {
  fetchCount() {
    return request
      .get('/questionnaire/count', {
        headers: {
          Authorization: `Bearer ${getCookie('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchList(apiParam) {
    return request
      .get(`/questionnaire/list?status=${apiParam.status}&start=${apiParam.start}&size=${apiParam.size}`, {
        headers: {
          Authorization: `Bearer ${getCookie('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchSearchList(apiParam) {
    return request
      .get(`/questionnaire/list?status=${apiParam.status}&q=${apiParam.q}&start=${apiParam.start}&size=${apiParam.size}`, {
        headers: {
          Authorization: `Bearer ${getCookie('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchAnswer(apiParam) {
    return request
      .post('/questionnaire/answer', apiParam, {
        headers: {
          Authorization: `Bearer ${getCookie('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchAnswerUpdate(apiParam) {
    return request
      .put('/questionnaire/answer_update', apiParam, {
        headers: {
          Authorization: `Bearer ${getCookie('token')}`,
        },
      })
      .then(({ data }) => data);
  },
};
export const basic = {
  fetchFile(fileName) {
    return request
      .get(`/photos/${fileName}`, {
        headers: {
          Authorization: `Bearer ${getCookie('token')}`,
        },
        responseType: 'blob',
      })
      .then(({ data }) => data);
  },
};
