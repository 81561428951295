import { util } from '@/api';
import { setCookie, getCookie, deleteCookies } from '@/utils/cookie';

const state = {
  maintaince: {
    userId: getCookie('userId') || '',
    token: getCookie('token') || '',
    code: '',
  },
  util: {
    /**
    drId: localStorage.getItem('drId') || '',
     */
  },
};

const mutations = {
  SET_LOGIN_RESPONSE(state, loginResponse) {
    state.maintaince.userId = loginResponse.userId;
    state.maintaince.token = loginResponse.token;
    state.maintaince.code = loginResponse.code;
  },
  /**
   HACK_TOKEN(state, token) {
     state.auth.token = token;
    }
    */
};

const getters = {
  SET_USER(state, data) {
    state.user = data || null;
  },
  /**
  GET_CHECK_RESULT: (state) => state.auth.isCheck
   */
};

const actions = {
  FETCH_LOGIN({ commit, getters }, payload) {
    return util.fetchLogin(payload).then((data) => {
      if (data.code && data.code === 'success') {
        const transedData = {
          userId: data.response.userId,
          token: data.response.token,
          code: data.code,
        };
        setCookie('userId', data.response.userId, {
          secure: true,
          expires: 30,
        });
        setCookie('token', data.response.token, { secure: true, expires: 30 });
        commit('SET_LOGIN_RESPONSE', transedData);
      }
      return data;
    });
  },
  LOGOUT({}) {
    return util.Logout();
  },
  FETCH_INFO() {
    return util.fetchInfo().then((data) => (data.response ? data.response : data));
  },
};
export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
