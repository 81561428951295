<template>
  <div class="login">
    <div class="login-wrap">
        <div class="login-title">
          <div class="img-wrap">
            <img src="@assets/images/kids.png" />
          </div>
          <h2>안아주기</h2>
          <br/>
          <h3>의료진 로그인</h3>
        </div>
        <div class="login-input">
            <div class="input-id">
                <input type="text" v-model="login.id.value" @input="checkId()" placeholder="아이디">
            </div>
            <p><span class="warn" v-text="login.id.errorTxt" v-if="login.id.hasError" /></p>
            <div class="input-pw">
                <input type="password" v-model="login.password.value" @keyup.enter="loginAction()" placeholder="비밀번호" @input="checkPw()">
            </div>
            <p><span class="warn" v-text="login.password.errorTxt" v-if="login.password.hasError" /></p>
        </div>
        <div class="login-btn">
            <div class="btn-wrap" :class="{ active: login.id.flag && login.password.flag }">
            <button type="button" @click="loginAction()" >로그인</button>
            </div>
        </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" @enterFlag="getEnterFlag"/>
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import { mapMutations, mapState, mapActions } from 'vuex';
import { getCookie, deleteCookies } from '@/utils/cookie';

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      login: {
        id: {
          flag: false,
          value: '',
          hasError: false,
          errorTxt: '아이디를 점검해주세요',
        },
        password: {
          flag: false,
          value: '',
          hasError: false,
          errorTxt: '비밀번호를 점검해주세요',
        },
      },
    };
  },

  created() {
    window.scrollTo(0, 0);
    if (getCookie('token') !== undefined && getCookie('token') !== null && getCookie('token') !== '') {
      this.$router.push('/medical');
    }
  },
  methods: {
    ...mapActions('util', ['FETCH_LOGIN']),
    ...mapMutations('basic', ['SET_POPUP', 'SET_SEARCH_POPUP']),
    getEnterFlag() { this.login.enterFlag = true; },
    checkId() {
      console.log('동작해 id');
      this.login.id.flag = false;
      const id = this.login.id.value.replace(/\s/gi, '');
      if (id === '') {
        this.login.id.flag = false;
        this.login.id.hasError = true;
        this.login.id.errorTxt = '아이디를 입력해주세요.';
        return false;
      }
      this.login.id.flag = true;
      this.login.id.hasError = false;
      this.login.id.errorTxt = '';
      return true;
    },
    checkPw() {
      this.login.password.flag = false;
      const password = this.login.password.value.replace(/\s/gi, '');
      if (password === '') {
        this.login.password.flag = false;
        this.login.password.hasError = true;
        this.login.password.errorTxt = '비밀번호를 입력해주세요.';
        return false;
      }
      this.login.password.flag = true;
      this.login.password.hasError = false;
      this.login.password.errorTxt = '';
      return true;
    },
    checkFlag() {
      if (!this.login.id.flag || !this.login.password.flag) {
        if (!this.login.id.flag) {
          this.login.id.hasError = true;
        } else if (!this.login.password.flag) {
          this.login.id.hasError = true;
        }
        return false;
      }
      return true;
    },
    async loginAction() {
      console.log('**loginAction**');
      if (this.checkFlag()) {
        const crypto = require('crypto');
        const id = this.login.id.value.replace(/\s/gi, '');
        // const password = this.login.password.value.replace(/\s/gi, '');
        const password = crypto
          .createHash('sha512')
          .update(this.login.password.value)
          .digest('base64');
        const userData = {
          id,
          password,
        };
        console.log(userData);
        await this.FETCH_LOGIN(userData).then((data) => {
          if (data.code === 'success') {
            if (getCookie('token')) {
              this.close();
            }
            this.SET_POPUP(true);
            this.popupSet.popType = 'warn';
            this.popupSet.enterTrigger = 'action';
            this.popupSet.popImg = 'success.svg';
            this.popupSet.content = '로그인이 되었습니다.';
            this.popupSet.cancelBtnText = null;
            this.popupSet.nextLink = '/medical';
            this.popupSet.confirmBtnText = '확인';
            this.popupSet.destination = true;
          } else {
            this.SET_POPUP(true);
            this.popupSet.popType = 'warn';
            this.popupSet.enterTrigger = 'close';
            this.popupSet.popImg = 'success.svg';
            this.popupSet.content = data.message;
            this.popupSet.cancelBtnText = '확인';
            this.popupSet.confirmBtnText = null;
          }
        });
      }
    },
    close() {
      this.SET_POPUP(false);
      this.$router.push('/medical');
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup', 'checkSearchPopup']),
  },
  components: {
    DefaultPopup,
  },
};
</script>

<style lang="scss" scoped>
.login {
    .login-wrap {
        position: absolute;
        top: calc(50% - 65px);
        left: 50%;
        transform: translate(-50%, -50%);
        width: 430px;
        margin: 0 0 40px 0;
    .img-wrap {
      display: flex;
      width:50%;
       margin:0 auto 25px;
      justify-content: center;
      img{width:100%}
    }
        .login-title {
            h2 {
            margin: 10px 0 0 0;
            @include initfontKor(
              $font-size: 50px,
              $line-height: 46px,
              $font-family: "AppleSDGothicNeoB00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: center
            );
            &:first-of-type {
              margin: 0;
            }
            }
            h3 {
              margin: 10px 0 0 0;
              @include initfontKor(
                $font-size: 40px,
                $line-height: 46px,
                $font-family: "AppleSDGothicNeoB00",
                $color: #292a2b,
                $letter-spacing: -0.6px,
                $text-align: center
              );
              &:first-of-type {
                margin: 0;
              }
            }
        }
        .login-input {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 10px;
            margin-top: 30px;
            p {
                height:26px;
                @include initfontKor(
                $font-size: 18px,
                $line-height: 26px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #ff0000,
                $letter-spacing: -0.6px,
                $text-align: left
                );
            }
            .input-id {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                input {
                    width: 100%;
                    height: 60px;
                    justify-content: center;
                    border: 1px solid #acb3c9;
                    border-radius: 8.5px;
                    font-family: "AppleSDGothicNeoB00";
                    padding: 0 12px;
                    color: #292a2b;
                    text-align: left;
                    line-height: 60px;
                    font-size: 26px;
                    letter-spacing: -0.6px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                }
            }
            .input-pw {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                input {
                    width: 100%;
                    height: 60px;
                    justify-content: center;
                    border: 1px solid #acb3c9;
                    border-radius: 8.5px;
                    font-family: "AppleSDGothicNeoB00";
                    padding: 0 12px;
                    color: #292a2b;
                    text-align: left;
                    line-height: 60px;
                    font-size: 26px;
                    letter-spacing: -0.6px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                }
            }
        }
        .login-btn {
            .btn-wrap {
                width: 100%;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8.5px;
                overflow: hidden;
                margin: 0 auto;
                &.active {
                    background: #fe6867;
                    button {
                    color: #fff;
                    }
                }
                background: #f3f3f3;
                button {
                    border: 0;
                    padding: 0;
                    height: 100%;
                    width: inherit;
                    background: transparent;
                    cursor: pointer;
                    @include initfontKor(
                    $font-size: 32px,
                    $line-height: 42px,
                    $font-family: "AppleSDGothicNeoB00",
                    $color: #292a2b,
                    $letter-spacing: -0.6px
                    );
                }
            }
        }
    }
}
</style>
