import Vue from 'vue';
import Vuex from 'vuex';
import basic from './modules/basic';
import util from './modules/util';
import questionnaire from './modules/questionnaire';

const modules = {
  basic,
  util,
  questionnaire,
};

const plugins = [
];
Vue.use(Vuex);
export const store = new Vuex.Store({
  modules,
  plugins,
});
