<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>

export default {
  props: {},
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
  computed: {
  },
  components: {
  },
};
</script>

<style lang="scss">
@import "assets/css/init.css";
@import "assets/fonts/stylesheet.css";

</style>
