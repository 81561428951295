/* eslint-disable no-useless-escape */
function setCookie(name, value, options = {}) {
  options = {};
  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }
  let updatedCookie =
    encodeURIComponent(name) + "=" + encodeURIComponent(value);
  for (const optionKey in options) {
    updatedCookie += "; " + optionKey;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }
  document.cookie = updatedCookie;
}

// 주어진 이름의 쿠키를 반환하는데,
// 조건에 맞는 쿠키가 없다면 undefined를 반환합니다.
function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      // eslint-disable-next-line no-useless-escape
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function deleteCookies(cookieList) {
  const allCookies = ["userId", "token"];
  if (!cookieList) {
    cookieList = allCookies;
  }
  const date = new Date();
  for (let i = 0; i < cookieList.length; i++) {
    document.cookie =
      cookieList[i] + "= " + "; expires=" + date.toUTCString() + "; path=/";
  }
}

/*
  setCookie('user', token, {secure: true, 'max-age' : 3600, 'expires' : 7});
   const result = getCookie('seek')
   deleteCookie('seek')
   */

export { setCookie, getCookie, deleteCookies };
