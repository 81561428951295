import Vue from 'vue';
import vueMoment from 'vue-moment';
import vSelect from 'vue-select';
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css';
import InfiniteLoading from 'vue-infinite-loading';
import { store } from './store/index';
import router from './router';
import App from './App.vue';

Vue.config.productionTip = false;

// datepicker 옵션
const datepickerOptions = {
  sundayFirst: true,
  dateLabelFormat: 'YYYY, MM D, dddd',
  days: ['월요일', '화요일', '수요일', '목요일', '금요일', '토요일', '일요일'],
  daysShort: ['월', '화', '수', '목', '금', '토', '일'],
  monthNames: [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ],
  colors: {
    selected: '#315754',
    inRange: 'rgb(255, 219, 219)',
    text: '#403f3f',
    selectedText: '#403f3f',
    disabled: 'transparent',
    inRangeBorder: '#e4e7e7',
    hoveredInRange: 'rgb(255, 219, 219)',
  },
};

Vue.use(AirbnbStyleDatepicker, datepickerOptions);
Vue.use(vSelect);
Vue.config.productionTip = false;
Vue.use(vueMoment);

const vm = new Vue({
  router,
  store,
  vueMoment,
  datepickerOptions,
  InfiniteLoading,
  render: (h) => h(App),
}).$mount('#app');
window.app = vm;
