var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", {
    attrs: { huge: _vm.popupSet.huge },
    on: {
      "forced-nextlink": function($event) {
        return _vm.goNextLink()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("div", [
              _vm.popType === "warn"
                ? _c("div", { staticClass: "default-pop frame" }, [
                    _c(
                      "div",
                      {
                        staticClass: "close",
                        on: {
                          click: function($event) {
                            return _vm.close()
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: { src: require("@assets/images/util/x.svg") }
                        })
                      ]
                    ),
                    !_vm.imgHide
                      ? _c("div", { staticClass: "pop-img" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/popup/" +
                                _vm.popImg)
                            }
                          })
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "content" }, [
                      _c("p", {
                        domProps: { innerHTML: _vm._s(_vm.contentMutated) }
                      })
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "btn-wrap",
                        class: {
                          alone:
                            _vm.confirmBtnText === null ||
                            _vm.cancelBtnText === null
                        }
                      },
                      [
                        _vm.cancelBtnText !== null
                          ? _c("button", {
                              ref: "close",
                              attrs: { type: "button" },
                              domProps: {
                                textContent: _vm._s(_vm.cancelBtnText)
                              },
                              on: {
                                click: function($event) {
                                  return _vm.close()
                                }
                              }
                            })
                          : _vm._e(),
                        _vm.confirmBtnText !== null
                          ? _c("button", {
                              ref: "action",
                              staticClass: "active",
                              attrs: { type: "button" },
                              domProps: {
                                textContent: _vm._s(_vm.confirmBtnText)
                              },
                              on: {
                                click: function($event) {
                                  return _vm.action()
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  ])
                : _vm._e(),
              _vm.popType === "img"
                ? _c("div", { staticClass: "default-pop frame imgPopWrap" }, [
                    _c(
                      "div",
                      {
                        staticClass: "close",
                        on: {
                          click: function($event) {
                            return _vm.close()
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: { src: require("@assets/images/util/x.svg") }
                        })
                      ]
                    ),
                    _c("div", { staticClass: "imgWrap" }, [
                      _c("p", [
                        _c("img", {
                          attrs: { src: "" + _vm.popupSet.imgPopUp }
                        })
                      ])
                    ])
                  ])
                : _vm._e()
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }