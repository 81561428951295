import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@page/util/Login.vue';
import MedicalPage from '@page/MedicalPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Login,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/medical',
    name: 'MedicalPage',
    component: MedicalPage,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
export default router;
