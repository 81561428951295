<template>
  <div class="medical-main">
    <div class="medical-wrap">
      <div class="btn-all">
        <div class="btn-item">
          <div
            class="btn-wrap"
            @click="changeStatus(1)"
            :class="activeStatus === 1 ? 'active' : ''"
          >
            <button>새로운 질문</button>
          </div>
          <div
            class="btn-wrap"
            @click="changeStatus(2)"
            :class="activeStatus === 2 ? 'active' : ''"
          >
            <button>완료된 질문</button>
          </div>
        </div>
      </div>
      <div class="side-bar">
        <div class="user-wrap">
          <div class="user-item">
            <div class="user-img">
              <p class="img-wrap"><img src="@/assets/images/doctor_profile.svg" alt="" /></p>
            </div>
            <p>{{ this.userInfo.nickname }}</p>
            <div class="hospital">
              <p>{{ this.userInfo.hospital }}</p>
            </div>
          </div>
          <div class="list-count">
            <div class="list-first">
              <p>전체 질문 수</p>
              <p>{{ totalCount }}</p>
            </div>
            <div class="list-second">
              <p>나의 답변 수</p>
              <p>{{ answerCount }}</p>
            </div>
          </div>
        </div>
        <div class="btn-logout" @click="logOut()">
          <button>로그아웃</button>
        </div>
      </div>
      <div class="medical-info-mainwrap">
        <div class="search-wrap">
          <input type="text" name="searchQ" v-model="searchQ" @keyup.enter="changeQ(searchQ)" />
          <button @click="changeQ(searchQ)">검색</button>
        </div>
        <div v-for="(item, index) in allRealList" :key="index">
          <div class="medical-info">
            <div class="info-wrap">
              <div class="info-top" @click="chageVisible(index)">
                <div class="info-burn">
                  <div class="burn-items">
                    <div class="burn-wrap">
                      <p>{{ item.cause }}</p>
                    </div>
                    <div class="burn-wrap">
                      <p>{{ item.causeDetail }}</p>
                    </div>
                  </div>
                  <img :class="`img${index}`" src="@/assets/images/dropdown.png" />
                </div>
                <div class="info-title">
                  <p>
                    작성자 : <b>{{ item.nickname }}</b> /
                    <b>{{ ageGroup[index] }}({{ ageList[index] + 1 }}세)</b>
                  </p>
                  <div class="title-mid">
                    <p v-if="burnDateList[index] - 1 === 0 || item.burnTime">
                      수상일 :
                      <b
                        >{{ $moment(item.burnDate).format("YYYY년 MM월 DD일") }},
                        {{ item.burnDayTime }}</b
                      >
                    </p>
                    <p v-else>
                      수상일 : <b>{{ $moment(item.burnDate).format("YYYY년 MM월 DD일") }}</b>
                    </p>
                  </div>
                  <p>
                    부위 : <b>{{ item.burnArea }}</b>
                  </p>
                  <p>
                    키워드 : <b>{{ item.wonder }}</b>
                  </p>
                  <p v-if="item.wonderDetail !== ''">
                    질문내용 : <b>{{ item.wonderDetail }}</b>
                  </p>
                </div>
              </div>
            </div>
            <div class="info-toggle" :class="`toggle-${index}`">
              <div class="toggle-main">
                <div class="toggle-left">
                  <p>질문일시</p>
                  <p>{{ $moment(item.createDate).format("YYYY-MM-DD HH:mm") }}</p>
                </div>
                <div class="toggle-right">
                  <div class="main-top">
                    <h2 v-if="burnDateList[index] - 1 === 0 || item.burnTime">수상 당일</h2>
                    <h2 v-else>{{ burnDateList[index] - 1 }}일차</h2>

                    <div class="answer-complete">
                      <p v-if="item.aid !== null" class="answerDone">답변완료</p>
                      <p v-else class="waiting">답변대기</p>
                    </div>
                  </div>
                  <div class="main-mid">
                    <div class="img-wrap">
                      <img @click="imgPopUp(`${item.photo_url1}`)" :src="`${item.photo_url1}`" />
                      <img @click="imgPopUp(`${item.photo_url2}`)" :src="`${item.photo_url2}`" />
                    </div>
                    <div class="main-input">
                      <div class="input-wrap">
                        <textarea
                          name="textarea"
                          id="textarea"
                          cols="30"
                          rows="5"
                          v-model="item.answer"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="answerDate" v-if="activeStatus === 2">
                    <div
                      v-if="item.answerUpdateDate !== item.answerCreateDate"
                      class="setAnswerDate"
                    >
                      <span>수정일시</span> : {{ item.answerCreateDate }} <br />
                      <span>작성자</span> : {{ item.anickname }}
                    </div>
                    <div v-else class="setAnswerDate">
                      <span>답변일시</span> : {{ item.answerUpdateDate }} <br />
                      <span>작성자</span> : {{ item.anickname }}
                    </div>
                  </div>
                  <div class="btn-wrap">
                    <div class="btn-item">
                      <button
                        type="button"
                        @click="updateAnswer(item.qid, item.aid, item.answer)"
                        v-if="item.aid !== null"
                      >
                        수정
                      </button>
                      <button type="button" @click="registerAnswer(item.qid, item.answer)" v-else>
                        등록
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <infinite-loading @infinite="infiniteHandler" spinner="waveDots"
          ><div slot="no-more"></div
        ></infinite-loading>
        <div class="top-action-btn" @click="moveTop()">
          <img src="@/assets/images/top_arrow.png" />
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import axios from 'axios';
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import InfiniteLoading from 'vue-infinite-loading';
import { mapMutations, mapState, mapActions } from 'vuex';
import { getCookie, deleteCookies } from '@/utils/cookie';

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      totalCount: 0,
      answerCount: 0,
      userInfo: {
        email: '',
        role: '',
        userId: '',
        nickname: '',
        phone: '',
        hospital: '',
      },
      statusFlag: true,
      status: '1',
      q: '',
      start: 0,
      size: 30,
      lists: [],
      ageList: [],
      ageGroup: [],
      searchQ: '',
      selectOption: '작성자',
      isVisible: [],
      burnDateList: [],
      limit: 0,
      // api 데이터의 값을 받는
      allList: [],
      // allList에서 응급의 데이터 빼서 받는
      allRealList: [],
      activeStatus: '',
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.isUnAuthorized();
    this.getUserInfo();
    this.getAllCount();
    this.SET_POPUP(false);
    // this.fetchList();
    if (this.$route.query.status) {
      this.activeStatus = this.$route.query.status;
    }
  },
  methods: {
    ...mapActions('basic', ['FETCH_FILE']),
    ...mapMutations('basic', ['SET_POPUP', 'SET_SEARCH_POPUP']),
    ...mapActions('util', ['FETCH_INFO', 'LOGOUT']),
    ...mapActions('questionnaire', [
      'FETCH_COUNT',
      'FETCH_LIST',
      'FETCH_SEARCH_LIST',
      'FETCH_ANSWER',
      'FETCH_ANSWER_UPDATE',
    ]),
    getTimeSetting() {
      for (let i = 0; i < this.allRealList.length; i++) {
        const { burnTime } = this.allRealList[i];
        if (burnTime) {
          const burnDayTime = burnTime.substring(0, 2);
          if (burnDayTime > 12) {
            this.allRealList[i].burnDayTime = `오후 ${burnDayTime - 12} : ${burnTime.substring(
              3,
              5,
            )}`;
          } else if (burnDayTime <= 12) {
            this.allRealList[i].burnDayTime = `오전 ${burnDayTime} : ${burnTime.substring(3, 5)}`;
          }
        }
      }
    },
    async getUserInfo() {
      try {
        await this.FETCH_INFO().then((data) => {
          console.log(data, 'data-info');
          this.userInfo.email = data.email;
          this.userInfo.role = data.role;
          this.userInfo.hospital = data.hospital;
          this.userInfo.phone = data.phone;
          this.userInfo.userId = data.userId;
          this.userInfo.nickname = data.nickname;
        });
      } catch (error) {
        console.log('error-info', error);
        if (error.status === 401) {
          deleteCookies();
          this.SET_POPUP(true);
          this.popupSet.popType = 'warn';
          this.popupSet.enterTrigger = 'action';
          this.popupSet.popImg = 'success.svg';
          this.popupSet.content = '로그아웃이 되었습니다.';
          this.popupSet.cancelBtnText = null;
          this.popupSet.nextLink = '/';
          this.popupSet.confirmBtnText = '확인';
          this.popupSet.destination = true;
        }
      }
    },
    imgPopUp(img) {
      this.SET_POPUP(true);
      this.popupSet.popType = 'img';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
      this.popupSet.imgPopUp = img;
      this.popupSet.huge = true;
    },
    async getAllCount() {
      try {
        await this.FETCH_COUNT().then((data) => {
          console.log(data, 'data-count');
          this.totalCount = data.cntAll;
          this.answerCount = data.cntMy;
        });
      } catch (error) {
        console.log('error', error);
        if (error.status === 401) {
          deleteCookies();

          this.SET_POPUP(true);
          this.popupSet.popType = 'warn';
          this.popupSet.enterTrigger = 'action';
          this.popupSet.popImg = 'success.svg';
          this.popupSet.content = '로그아웃이 되었습니다.';
          this.popupSet.cancelBtnText = null;
          this.popupSet.nextLink = '/';
          this.popupSet.confirmBtnText = '확인';
          this.popupSet.destination = true;
        }
      }
    },
    async logOut() {
      await this.LOGOUT().then((data) => {
        deleteCookies();

        this.SET_POPUP(true);
        this.popupSet.popType = 'warn';
        this.popupSet.enterTrigger = 'action';
        this.popupSet.popImg = 'success.svg';
        this.popupSet.content = '로그아웃이 되었습니다.';
        this.popupSet.cancelBtnText = null;
        this.popupSet.nextLink = '/';
        this.popupSet.confirmBtnText = '확인';
        this.popupSet.destination = true;
      });
    },
    isUnAuthorized() {
      if (
        getCookie('token') === undefined
        || getCookie('token') === null
        || getCookie('token') === ''
      ) {
        this.SET_POPUP(true);
        this.popupSet.content = '로그인 권한이 필요합니다.';
        this.popupSet.popImg = 'warning.svg';
        this.popupSet.cancelBtnText = null;
        this.popupSet.nextLink = '/login';
        this.popupSet.confirmBtnText = '확인';
        this.popupSet.destination = true;
        return false;
      }
      return true;
    },
    changeQ(q) {
      if (!getCookie('token')) {
        this.SET_POPUP(false);
        this.$router.push('/');
      }
      this.q = q;
      if (this.$route.query.status) {
        if (this.q !== '') {
          this.$router.push({
            name: 'MedicalPage',
            query: { q: this.q, status: this.$route.query.status },
          });
        } else {
          this.$router.push({
            name: 'MedicalPage',
            query: { status: this.$route.status },
          });
        }
      } else if (this.q !== '' && !this.$route.query.status) {
        this.$router.push({
          name: 'MedicalPage',
          query: { q: this.q },
        });
      } else {
        this.$router.push({
          name: 'MedicalPage',
        });
      }
    },
    changeStatus(status) {
      this.status = status;
      if (this.status !== '') {
        this.$router.push({
          name: 'MedicalPage',
          query: { status: this.status },
        });
      } else {
        this.$router.push({
          name: 'MedicalPage',
        });
      }
    },
    chageVisible(index) {
      if (!this.isVisible[index]) {
        document.querySelector(`.img${index}`).style = 'transform: rotate(180deg)';
        document.querySelector(`.toggle-${index}`).style = 'display: block';
        this.isVisible[index] = true;
      } else {
        document.querySelector(`.toggle-${index}`).style = 'display: none';
        document.querySelector(`.img${index}`).style = 'transform: rotate(0deg)';
        this.isVisible[index] = false;
      }
    },
    moveTop() {
      window.scrollTo(0, 0);
    },
    infiniteHandler($state) {
      if (this.$route.query.q) {
        this.q = this.$route.query.q;
      }
      if (this.$route.query.status) {
        this.status = this.$route.query.status;
      }
      const apiParam = {
        status: this.status,
        q: this.q,
        start: this.start,
        size: this.size,
      };
      let realApi = '';
      if (this.q === undefined || this.q === '' || this.q === null) {
        realApi = `${process.env.VUE_APP_API_URL}/questionnaire/list?status=${apiParam.status}&start=${apiParam.start}&size=${apiParam.size}`;
      } else {
        realApi = `${process.env.VUE_APP_API_URL}/questionnaire/list?status=${apiParam.status}&q=${apiParam.q}&start=${apiParam.start}&size=${apiParam.size}`;
      }
      axios
        .get(realApi, {
          headers: {
            Authorization: `Bearer ${getCookie('token')}`,
          },
        })
        .then((response) => {
          setTimeout(() => {
            if (response.data.response.length) {
              console.log('kyg', response.data);
              this.allList = this.allList.concat(response.data.response);
              // 응급데이터를 삭제!
              for (let i = 0; i < this.allList.length; i++) {
                const areaEmergency = this.allList[i].burnArea.includes('음부');
                const burnSizeEmergency = this.allList[i].burnSize >= 1;
                if (
                  this.allList[i].cause.includes('흡입화상')
                  || this.allList[i].cause.includes('화학화상')
                  || this.allList[i].cause.includes('화염화상')
                  || this.allList[i].cause.includes('전기화상')
                  || areaEmergency
                  || burnSizeEmergency
                ) {
                  this.allList[i].flag = false;
                }
              }

              this.allRealList = this.allList.filter((item) => item.flag !== false);

              const date = new Date();
              const year = date.getFullYear();
              let birthValue;
              let birthRange;
              let resultValue = '';
              for (let i = 0; i < this.allRealList.length; i++) {
                if (this.allRealList[i].photo_url1.indexOf('blob:') <= -1) {
                  this.allRealList[i].photo_url1 = this.allRealList[i].photo_url1
                    .replace('/api/v1/photos/', '')
                    .replace('http://localhost:8080', '');
                  // .replace('http://localhost:8081', '');
                  this.FETCH_FILE(this.allRealList[i].photo_url1).then((data) => {
                    this.allRealList[i].photo_url1 = URL.createObjectURL(data);
                    console.log('data : ', data);
                  });

                  this.allRealList[i].photo_url2 = this.allRealList[i].photo_url2
                    .replace('/api/v1/photos/', '')
                    .replace('http://localhost:8080', '');
                  // .replace('http://localhost:8081', '');
                  this.FETCH_FILE(this.allRealList[i].photo_url2).then((data) => {
                    this.allRealList[i].photo_url2 = URL.createObjectURL(data);
                    console.log('data : ', data);
                  });
                }
              }

              this.ageGroup = [];
              this.ageList = [];
              this.burnDateList = [];
              this.isVisible = [];
              for (let i = 0; i < this.allRealList.length; i++) {
                birthValue = Number(year) - Number(this.allRealList[i].birthDate.substr(0, 4));
                this.ageList.push(birthValue);
                birthRange = String(birthValue).substr(0, 1);
                if (birthValue >= 10) {
                  resultValue = `${birthRange}0대`;
                } else {
                  resultValue = '10대 미만';
                }
                this.ageGroup.push(resultValue);
                this.isVisible.push(false);

                const createBurn = new Date(this.allRealList[i].createDate);
                const DateBurn = new Date(this.allRealList[i].burnDate);
                const burnValue = createBurn.getTime() - DateBurn.getTime();
                console.log(burnValue, ' : burnValue');
                const result = Math.ceil(burnValue / (1000 * 60 * 60 * 24));

                this.burnDateList.push(result);
              }
              this.getTimeSetting();
              $state.loaded();
              this.start += 10;

              if (this.allRealList.length / 10 === 0) {
                $state.complete();
              }
            } else {
              $state.complete();
            }
          }, 1000);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    registerAnswer(id, textContent) {
      const apiParam = {
        qid: id,
        content: textContent,
      };
      this.FETCH_ANSWER(apiParam).then((data) => {
        console.log(data, 'answer-data');
      });
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.enterTrigger = 'action';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '답변이 등록 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    updateAnswer(qid, aid, textContent) {
      const apiParam = {
        qid,
        aid,
        content: textContent,
      };
      this.FETCH_ANSWER_UPDATE(apiParam).then((data) => {
        console.log(data, 'answer-update-data');
      });
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.enterTrigger = 'action';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '답변이 수정 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
  },
  components: {
    DefaultPopup,
    InfiniteLoading,
  },
  computed: {
    ...mapState('basic', ['checkPopup', 'checkSearchPopup']),
  },
};
</script>

<style lang="scss" scoped>
.medical-main {
  .medical-wrap {
    .btn-all {
      display: flex;
      justify-content: space-between;
      margin: 20px auto 20px 22.5%;
      .btn-item {
        gap: 50px;
        display: flex;
        .btn-wrap {
          cursor: pointer;
          box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
          width: 300px;
          height: 70px;
          background: #fff5de;
          border-radius: 5px;
          &.active {
            border: 4px solid #fe6867;
          }
          button {
            cursor: pointer;
            width: 100%;
            height: 100%;
            background: none;
            color: #343434;
            border: none;
            font-weight: normal;
            font-size: 23px;
            font-family: "AppleSDGothicNeoB00";
          }
        }
      }
    }
    .side-bar {
      position: fixed;
      display: flex;
      flex-direction: column;
      width: 323px;
      height: 100%;
      border: 1px solid #d2d2d2;
      gap: 0;
      top: 0;
      .btn-logout {
        margin: 0 auto;
        cursor: pointer;
        width: 100%;
        height: 52px;
        background: #fff5de;
        button {
          cursor: pointer;
          width: 100%;
          height: 100%;
          background: none;
          color: #000000;
          background-color: #d2d2d2;
          border: none;
          font-weight: normal;
          font-size: 18px;
          font-family: "AppleSDGothicNeoB00";
          &:hover {
            background-color: #fe6867;
            color: #fff;
          }
        }
      }
      .user-wrap {
        .user-item {
          height: 320px;
          .user-img {
            width: 100%;
            height: 121px;
            line-height: 121px;
            margin: 43px 0 23px 0;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff5de;
            .img-wrap {
              width: 120px;
              height: 120px;
              background-color: #fff;
              box-sizing: border-box;
              border-radius: 120px;
              border: 1px solid #707070;
              overflow: hidden;
            }
          }
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #fff5de;
          p {
            font-family: "AppleSDGothicNeoB00";
            font-size: 32px;

            font-weight: normal;
            margin: 2px auto;
          }
          .hospital {
            width: 100%;
            height: 80px;
            margin: 10px auto;
            p {
              font-family: AppleSDGothicNeoM00;
              font-size: 29px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.31;
              letter-spacing: normal;
              text-align: center;
              color: #000;
            }
          }
        }
        .list-count {
          display: flex;
          padding: 30px 0;
          margin: 0 auto;
          width: 100%;
          background-color: rgba(215, 218, 220, 0.26);
          .list-first,
          .list-second {
            width: 45%;
            margin: auto;
            p {
              font-family: "appleSDGothicNeoB00";
              font-size: 18px;
              color: #000000;
              &:nth-child(2) {
                color: #343434;
                font-weight: normal;
                font-family: "AppleSDGothicNeoB00";
                color: #fe6867;
              }
            }
          }
          p {
            color: #a4a4a4;
            text-align: center;
            margin: 2px auto;
          }
        }
      }
    }
    .medical-info-mainwrap {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      .search-wrap {
        display: block;
        text-align: right;
        height: 25px;
        margin: 10px auto 0 auto;
        width: 55%;
        select {
          font-family: "AppleSDGothicNeoSB00";
          font-size: 15px;
          border: 1px solid #d2d2d2;
          color: #d2d2d2;
          height: 100%;
          margin: auto 5px;
        }
        input {
          font-family: "AppleSDGothicNeoSB00";
          font-size: 15px;
          border: 1px solid #a4a4a4;
          width: 30%;
          height: 100%;
          margin: auto 2px;
        }
        button {
          cursor: pointer;
          box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
          font-family: "AppleSDGothicNeoSB00";
          font-size: 15px;
          background: #fff5de;
          color: #343434;
          border: none;
          height: 25px;
          margin: auto 0 auto 5px;
        }
      }
      .medical-info {
        width: 55%;
        margin: 10px auto;
        .info-wrap {
          cursor: pointer;
          border: 1px solid #d2d2d2;
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          .info-top {
            margin: 20px 30px;
            .info-burn {
              display: flex;
              justify-content: space-between;
              height: 20px;
              width: 100%;
              .burn-items {
                display: flex;
                width: 100%;
                .burn-wrap {
                  margin: 0 10px 0 0;

                  p {
                    font-family: "AppleSDGothicNeoB00";
                    font-size: 15px;
                    margin: auto;
                    padding: 10px;
                    text-align: center;
                    line-height: 0.5;
                    font-weight: normal;
                    color: #343434;
                  }
                  background: #fff5de;
                  border-radius: 5px;
                  width: auto;
                  height: 25px;
                }
              }
            }
            .info-title {
              margin-top: 10px;
              p {
                font-family: "AppleSDGothicNeoSB00";
                font-size: 15px;
                color: #343434;
                margin: 5px auto 0 auto;
                font-weight: normal;
              }
            }
          }
          .info-bottom {
            padding: 10px 30px;
            width: 100%;
            height: 100px;
            display: flex;
            .info-main {
              width: 85%;
              justify-self: center;
              height: 100%;
              border: 1px solid #a4a4a4;
              display: flex;
              align-items: center;
              justify-content: center;
              h2 {
                font-family: "AppleSDGothicNeoB00";
                font-size: 30px;
                font-weight: normal;
              }
            }
            .info-main-count {
              width: 15%;
              height: 100%;
              display: flex;
              align-items: flex-end;
              justify-content: flex-end;
              p {
                width: 75px;
                height: 20px;
                text-align: center;
                border: 1px solid #d2d2d2;
                color: #a4a4a4;
                border-radius: 5px;
                line-height: 1.5;
                font-family: "AppleSDGothicNeoB00";
                font-size: 15px;
              }
            }
          }
        }
        .info-toggle {
          width: 100%;
          height: auto;
          background: #f1f3f5;
          display: none;
          .toggle-main {
            padding: 30px 10px;
            display: flex;
            .toggle-left {
              width: 18%;
              margin-top: 8px;
              p {
                line-height: 1.3;
                font-size: 16px;
                color: #a4a4a4;
              }
            }
            .toggle-right {
              width: 85%;
              background: #fff;
              border: 1px solid #d2d2d2;
              display: flex;
              flex-direction: column;
              .answerDate {
                width: 100%;
                text-align: right;
                padding: 0 20px;
              }
              .main-top {
                padding: 10px 20px;
                display: flex;
                justify-content: space-between;
                h2 {
                  font-family: "AppleSDGothicNeoB00";
                  font-size: 18px;
                  font-weight: normal;
                }
                .answer-complete {
                  // border: 1px solid #d2d2d2;
                  width: 70px;
                  text-align: center;
                  line-height: 1.5;
                  height: 20px;
                  font-size: 14px;
                  color: #fff;
                  .waiting {
                    border-radius: 5px;
                    background-color: #fe6867;
                  }
                  .answerDone {
                    border-radius: 5px;
                    background-color: #23cc89;
                  }
                }
              }
              .main-mid {
                border-top: 1px solid #d2d2d2;
                padding: 20px;
                .img-wrap {
                  display: flex;
                  flex-wrap: wrap;
                  border-radius: 0;
                  img {
                    width: 150px;
                    height: auto;
                    margin-left: 10px;
                    border-radius: 0;
                    &:first-of-type {
                      margin: 0;
                    }
                  }
                }
                .answer-check {
                  display: flex;
                  margin: 10px 0;
                  gap: 10px;
                  p {
                    font-family: "AppleSDGothicNeoB00";
                    font-size: 15px;
                    line-height: 2;
                    color: #a4a4a4;
                  }
                  img {
                    transform: rotate(180deg);
                  }
                }
                .main-input {
                  margin: 30px 0 0 0;
                  textarea {
                    padding: 5px;
                    background: #f1f3f5;
                    width: 100%;
                    border: 1px solid #d2d2d2;
                    resize: none;
                  }
                }
              }
              .btn-wrap {
                margin: 10px auto 20px auto;
                width: 100px;
                height: 30px;
                .btn-item {
                  background-color: #fe6867;
                  width: 100%;
                  height: 100%;
                  button {
                    cursor: pointer;
                    width: 100%;
                    height: 100%;
                    background: #fe6867;
                    color: #fff;
                    border: none;
                    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
                  }
                }
              }
            }
          }
        }
      }
      .top-action-btn {
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: 40px;
        right: 200px;
        background: #fff5de;
        height: 50px;
        width: 50px;
        border-radius: 5px;
        cursor: pointer;
        img {
          cursor: pointer;
          transform: rotate(270deg);
          width: 25px;
          height: 25px;
          margin: auto;
        }
      }
    }
  }
}
</style>
